import * as mdb from '../../../vendor/assets/javascripts/mdb.umd.min.js';
import * as htmx from '../../../vendor/assets/javascripts/htmx.min.js';

window.mdb = mdb;
window.htmx = htmx;

addEventListener('DOMContentLoaded', (event) => {

  mdb.initMDB({
    Collapse: mdb.Collapse,
    Ripple: mdb.Ripple,
    Input: mdb.Input,
    Datepicker: mdb.Datepicker,
    Select: mdb.Select,
    Tab: mdb.Tab,
    Dropdown: mdb.Dropdown,
    Tooltip: mdb.Tooltip,
  });
});
